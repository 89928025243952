type Strings = {
  [locale: string]: {
    [name: string]: string;
  };
};

export const LangStrings: Strings = {
  pt: {
    about: 'Sobre',
    aboutme: 'Sobre o autor',
    aboutcontent: 'Sobre o conteúdo deste site',
    aboutsite: 'Sobre este site',
    allarticles: 'Todos os artigos',
    articles: 'Artigos',
    comments: 'Comentários',
    click: 'Clique aqui!',
    home: 'Início',
    languages: 'Idiomas',
    nomessage: 'Que tal tentar procurar com outro termo?',
    noresults: 'Sem resultados para',
    portfolio: 'Meu portfólio',
    results: 'Resultados encontrados',
    search: 'Busca',
    searchphrase: 'Use o campo acima para encontrar um artigo no site',
    support: 'Apoie este blog',
    supportphrase:
      'Se este artigo te ajudou de alguma forma, considere fazer uma doação. Isto vai me ajudar a criar mais conteúdos como este!',
    title: 'Título do artigo',
  },
  en: {
    about: 'About',
    aboutme: 'About the author',
    aboutcontent: 'About this blog contents',
    aboutsite: 'About this site',
    allarticles: 'All articles',
    articles: 'Articles',
    comments: 'Comments',
    click: 'Click here!',
    home: 'Home',
    languages: 'Languages',
    nomessage: 'What about trying with a different term?',
    noresults: 'No matches for',
    portfolio: 'My portfolio',
    results: 'Results found',
    search: 'Search',
    searchphrase: 'Use the field above to search for an article in the site',
    support: 'Support this blog',
    supportphrase:
      'If this article helped you in some way, consider donating. This will help me to create more content like this!',
    title: 'Article title',
  },
};
