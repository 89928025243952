import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { LangStrings } from '../lib/strings';

type LanguageData = {
  locale: string;
  setLocale: Dispatch<SetStateAction<string>>;
};

type LanguageProviderProps = {
  children: ReactNode;
};

export const defaultLocale = 'pt';
export const locales = ['pt', 'en'];
export const LanguageContext = createContext({} as LanguageData);

export function LanguageProvider({ children }: LanguageProviderProps) {
  const [locale, setLocale] = useState('pt');

  useEffect(() => {
    if (!window) {
      return;
    }

    const language = localStorage.getItem('@elf::lang') || locale;
    setLocale(language);
  }, [locale]);
  return (
    <LanguageContext.Provider value={{ locale, setLocale }}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useTranslation() {
  const { locale, setLocale } = useContext(LanguageContext);

  function t(key: string) {
    if (!LangStrings[locale][key]) {
      console.warn(`No string '${key}' for locale '${locale}'`);
    }

    return LangStrings[locale][key] || LangStrings[defaultLocale][key] || '';
  }

  return { t, locale, setLocale, locales };
}
