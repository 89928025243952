import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const theme = {
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  styles: {
    global: (props) => ({
      "*": {
        scrollbarColor: "#aaa transparent",
        scrollbarWidth: "thin",
      },
      body: {
        bg: mode("gray.100", "gray.900")(props),
        color: mode("gray.800", "gray.100")(props),
      },
      "::-webkit-scrollbar": {
        h: "3px",
        w: "3px",
      },
      "::-webkit-scrollbar-track": {
        bg: "transparent",
      },
      "::-webkit-scrollbar-thumb": {
        bg: "gray.400",
      },
      a: {
        transition: "all .2s",
      },
      h2: {
        fontSize: ["2rem", "2.5rem"],
        fontWeight: "black",
        lineHeight: 1,
        m: "3rem 1rem 0 0",
        textAlign: "center",
      },
      h3: {
        fontSize: "1.5rem",
        fontWeight: "black",
        lineHeight: 1,
        m: "2rem 1rem 1rem 0",
      },
      h4: {
        fontSize: "1.2rem",
        fontWeight: "black",
        my: 4,
      },
      hr: {
        borderStyle: "dashed",
        m: "4rem auto",
        maxW: "60vw",
        w: "100%",
      },
      "ul, ol": {
        fontSize: ["1rem", "1.2rem"],
        ml: "2rem",
      },
      ".gatsby-highlight": {
        my: "4rem",
        fontFamily: "Fira Code",
      },
      cite: {
        color: "muted",
        display: "block",
        fontSize: "0.8em",
        m: 2,
      },
      button: {
        minHeight: 5,
      },
      footer: {
        mt: "5rem",
      },
      ".active": {
        opacity: 0.5,
      },
      p: {
        color: mode("gray.500", "gray.300")(props),
        fontSize: ["1rem", "1.3rem"],
        my: 4,
      },
      blockquote: {
        my: "4rem",
        textAlign: "center",
      },
      'pre[class*="language-"], code[class*="language-"]': {
        fontFamily: "Fira Code",
      },
      ':not(pre) > code[class*="language-"]': {
        color: "purple",
        fontSize: "1rem",
      },
      "p > a, ul li > a": {
        color: mode("darkblue", "blue")(props),
      },
      "p > img": {
        borderRadius: "md",
        d: "block",
        m: "auto",
      },
      "img ~ em": {
        color: mode("muted", "mutedlight"),
        d: "block",
        p: "1rem",
        fontSize: "md",
        textAlign: "center",
      },
      "blockquote p": {
        color: mode("gray.800", "gray.100")(props),
        fontSize: "1.7rem",
        fontWeight: "bold",
      },
      "blockquote a": {
        bg: mode("gray.800", "gray.100")(props),
        color: mode("gray.100", "gray.800")(props),
        px: "0.5rem",
      },
      "blockquote a:hover": {
        bg: mode("sand", "gold")(props),
      },
      "blockquote div": {
        my: "2rem",
      },
      ".article-content h3": {
        color: "gold",
      },
      ".embed": {
        alignSelf: "center",
        display: "block",
        maxW: "100%",
        pos: "relative",
        w: "100%",
      },
      ".embed iframe": {
        border: 0,
        h: "100%",
        pos: "absolute",
        w: "100%",
      },
      ".widescreen": {
        pb: "56.25%",
      },
    }),
  },
  fonts: {
    body: "Fira Sans",
    heading: "Fira Sans",
    mono: "Fira Code",
  },
  colors: {
    gray: {
      100: "#f8f8f8",
      200: "#eee",
      300: "#ddd",
      400: "#afafaf",
      500: "#5f5f5f",
      700: "#303030",
      800: "#202020",
      900: "#121212",
    },
    blue: "#87afd7",
    cyan: "#7cdce7",
    darkblue: "#6787af",
    darkpurple: "#9787af",
    gold: "#d7af87",
    green: "#2ec27e",
    muted: "#3a3b3f",
    mutedlight: "#afafaf",
    purple: "#d7d7ff",
    red: "#fd6389",
    sand: "#af875f",
    scarlet: "#dd4c4f",
    teal: "#5fafaf",
  },
};

export default extendTheme(theme);
